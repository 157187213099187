<template>
   <div class="flex gap-2 mb-2 text-sm">
      <CalendarIcon class="w-5 h-5 text-primary-500 inline-block" />
      <span class="text-sm text-gray-200"> {{ useDateTime(date) }}</span>
   </div>
   <div class="flex gap-2 text-sm">
      <ClockIcon class="w-5 h-5 text-primary-500 inline-block" />
      <span class="text-sm text-gray-200">
         {{ useTimeFormat(date) }}
      </span>
   </div>
</template>
<script setup lang="ts">
   const { useDateTime, useTimeFormat } = useTimeComposable();
   import { CalendarIcon, ClockIcon } from '@heroicons/vue/24/outline';
   defineProps<{
      date: number | string | Date;
   }>();
</script>
